import "core-js/modules/es.array.push.js";
import { computed, ref, watch } from 'vue';
import { categoryMap } from '@/common/index.js';

// const configList = configJson;

export default {
  props: {
    show: false,
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props) {
    // const showMenu = ref(props.show);
    function categorizeByCategory(items) {
      return items.reduce((acc, item) => {
        // 如果累加器中还没有当前项的 category，就初始化一个数组
        if (!acc[item.category]) {
          acc[item.category] = [];
        }
        // 将当前项添加到对应 category 的数组中
        acc[item.category].push(item);
        return acc;
      }, {});
    }
    function clickOverlay() {
      // console.log("clickOverlay");
    }
    function goHome() {
      window.location.href = window.location.origin;
    }
    watch(props, () => {
      showMenu.value = true;
    });
    const showMenu = ref(false);
    // const typeNameMap = categoryMap;
    console.log("typeNameMap", categoryMap);
    // const topGame = configList.menuGames;
    // console.log("game", Object.keys(typeNameMap));
    return {
      // showMenu: computed(_ => props.show),
      showMenu,
      list: categoryMap,
      clickOverlay,
      // topGame,
      goHome
    };
  }
};