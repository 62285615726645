export default {
  props: {
    name: String
  },
  setup(props) {
    function handleBack() {
      // console.log("handleBack");
      window.history.back();
    }
    return {
      handleBack,
      name: props.name
    };
  }
};