import { ref } from 'vue';
import PopupBar from './PopupBar.vue';
export default {
  components: {
    PopupBar
  },
  props: {
    showInput: {
      default: false
    },
    inputVal: ''
  },
  setup(props) {
    /**
     * 點擊展示搜索框
     */
    const showPop = ref(false);
    const showSerachInput = ref(props.showInput);
    const inputValue = ref(props.inputVal);
    function handleClick() {
      showSerachInput.value = !showSerachInput.value;
    }
    function handleSerach() {
      window.location.href = `/search.html?keywords=${inputValue.value}`;
    }
    function goHome() {
      window.location.href = window.location.origin;
    }
    function handleClickMenu() {
      showPop.value = !showPop.value;
    }
    return {
      handleClick,
      showSerachInput,
      handleSerach,
      inputValue,
      goHome,
      showPop,
      handleClickMenu
    };
  }
};