import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import { onMounted, onUnmounted, ref } from 'vue';
export default {
  __name: 'goBack',
  setup(__props) {
    const show = ref(false);
    // 定义处理滚动的函数
    const handleScroll = () => {
      const viewportHeight = window.innerHeight; // 获取视口高度，相当于 100vh
      const scrollDistance = window.scrollY; // 获取当前滚动的距离

      // 如果滚动距离大于或等于视口高度
      if (scrollDistance >= viewportHeight) {
        // scrollToTop();
        show.value = true;
      } else {
        show.value = false;
      }
    };

    // 定义滚动到顶部的函数
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      });
    };
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return show.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "back_top",
        class: "default_back_top back_top",
        style: {
          "display": "block"
        },
        onClick: scrollToTop
      }, [_createVNode(_component_van_icon, {
        name: "back-top"
      })])) : _createCommentVNode("", true);
    };
  }
};