import configJson from '@/assets/json/config.json';

// console.log("testtttt", configJson);

function categorizeByCategory(items,byName) {
    return items.reduce((acc, item) => {
        const useName = item[byName];
        // 如果累加器中还没有当前项的 category，就初始化一个数组
        if (!acc[useName]) {
            acc[useName] = [];
        }
        acc[useName].push(item);
        return acc;
    }, {});
}

const preIconUrl = configJson.pre_icon_url;
const preGameUrl = configJson.pre_game_url;
const preImgUrl = configJson.pre_img_url;

const appList = configJson.games.map(item => {
    const appLogo = `${preImgUrl}${item.image}`;
    const typeLogo = `${preIconUrl}/${item.category}.svg`;
    return { ...item, appLogo, typeLogo };
});

let categoryMap = appList.map(_ => {
    return {
        name: _.category,
        logo: _.typeLogo
    };
});

categoryMap = categorizeByCategory(categoryMap,'name');
const appListMapByCategory = categorizeByCategory(appList,'category');

const urlConfig = {
    preIconUrl,
    preGameUrl,
    preImgUrl,
};
export {
    appList,
    urlConfig,
    categoryMap,
    appListMapByCategory,
};